import { gql, useQuery } from '@apollo/client';
import { Loading } from 'components/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import { ConsignmentsQuery, ConsignmentsQueryVariables } from 'graphql/types';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useTable } from 'react-table';
import { formatDateAndTime } from 'utils/misc';
import { buildRoute } from 'utils/routes';
import {
  ConsignmentFilter,
  useBuildConsignmentQueryFilter,
} from './consignment-filter';

const consignmentsQuery = gql`
  query Consignments($input: ConsignmentsInput!) {
    consignments(input: $input) {
      consignments {
        id
        order {
          id
        }
        status
        lineItems {
          id
        }
        createdAt
        fulfilledAt
      }
      totalCount
      nextPageToken
    }
  }
`;

type ConsignmentRowData = {
  id: string;
  orderId: string;
  status: string;
  lineItems: number;
  createdAt: Date;
  fulfilledAt: Date | null;
};

const TABLE_PAGE_SIZE = 30;

const Consignments = (): ReactElement => {
  const history = useHistory();

  const activeFilter = useBuildConsignmentQueryFilter();
  const [pageDetails, setPageDetails] = useState({
    totalCount: 0,
    currentPageNumber: 1,
    pageNumberToToken: new Map([[1, '']]),
  });

  const handleFilterChange = useCallback(() => {
    setPageDetails({
      totalCount: 0,
      currentPageNumber: 1,
      pageNumberToToken: new Map([[1, '']]),
    });
  }, []);

  const { data, loading, client } = useQuery<
    ConsignmentsQuery,
    ConsignmentsQueryVariables
  >(consignmentsQuery, {
    variables: {
      input: {
        search: activeFilter.ids
          ? { ids: activeFilter.ids }
          : { filter: activeFilter.filter },
        pageRequest: {
          pageSize: TABLE_PAGE_SIZE,
          pageToken: pageDetails.pageNumberToToken.get(
            pageDetails.currentPageNumber,
          ),
        },
      },
    },
    onCompleted: ({ consignments }) => {
      setPageDetails((pd) => ({
        totalCount: consignments?.totalCount ?? 0,
        currentPageNumber: pd.currentPageNumber,
        pageNumberToToken: consignments?.nextPageToken
          ? new Map(pd.pageNumberToToken).set(
              pd.currentPageNumber + 1,
              consignments.nextPageToken,
            )
          : pd.pageNumberToToken,
      }));
    },
  });

  const consignmentRowData = useMemo(
    () => processConsignmentData(data),
    [data],
  );

  const tableInstance = useTable({
    columns,
    data: consignmentRowData,
  });

  return (
    <div>
      <ConsignmentFilter onChange={handleFilterChange} />
      <Table tableInstance={tableInstance}>
        <TableHead />
        <TableBody>
          {tableInstance.rows?.map((row) => {
            tableInstance.prepareRow(row);

            return (
              <TableRow row={row} key={row.id}>
                {row.cells.map((cell) => (
                  <TableCell
                    key={`${cell.row.original.id}-${cell.column.id}`}
                    cell={cell}
                    onClick={(): void => {
                      history.push(
                        buildRoute.coreOrder(cell.row.original.orderId),
                      );
                    }}
                  />
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!consignmentRowData.length && !loading && (
        <div className="text-center font-medium pt-8">
          No consignments found
        </div>
      )}
      <div className="w-full flex justify-end items-center gap-2 pt-2">
        <div className="h-10 w-10 flex justify-center items-center">
          {loading && <Loading />}
        </div>
        <button
          className="w-10 h-10 first:ml-0 border rounded text-slate-600 border-slate-600 enabled:hover:bg-slate-400 disabled:text-slate-500 disabled:border-slate-500 disabled:cursor-not-allowed"
          onClick={() => {
            client.cache.evict({
              fieldName: 'consignments',
            });
            client.cache.gc();
            setPageDetails((pd) => ({
              totalCount: pd.totalCount,
              currentPageNumber: pd.currentPageNumber - 1,
              pageNumberToToken: new Map(pd.pageNumberToToken),
            }));
          }}
          disabled={
            loading ||
            pageDetails.pageNumberToToken.get(
              pageDetails.currentPageNumber - 1,
            ) === undefined
          }
        >
          <FaChevronLeft className="mx-auto stroke-current" />
        </button>
        <div className="px-2 flex justify-center items-center">
          {`Page ${pageDetails.currentPageNumber} of ${
            Math.ceil(pageDetails.totalCount / TABLE_PAGE_SIZE) || 1
          }`}
        </div>
        <button
          className="w-10 h-10 ml-2 first:ml-0 border rounded text-slate-600 border-slate-600 enabled:hover:bg-slate-400 disabled:text-slate-500 disabled:border-slate-500 disabled:cursor-not-allowed"
          onClick={() => {
            client.cache.evict({
              fieldName: 'consignments',
            });
            client.cache.gc();
            setPageDetails((pd) => ({
              totalCount: pd.totalCount,
              currentPageNumber: pd.currentPageNumber + 1,
              pageNumberToToken: new Map(pd.pageNumberToToken),
            }));
          }}
          disabled={
            loading ||
            pageDetails.pageNumberToToken.get(
              pageDetails.currentPageNumber + 1,
            ) === undefined
          }
        >
          <FaChevronRight className="mx-auto stroke-current" />
        </button>
      </div>
    </div>
  );
};

const columns: Column<ConsignmentRowData>[] = [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Line Items',
    accessor: 'lineItems',
    Cell: (c) => <div>{c.value}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: (
      cell: CellProps<ConsignmentRowData, ConsignmentRowData['createdAt']>,
    ): React.ReactElement => <div>{formatDateAndTime(cell.value)}</div>,
    className: 'w-1/5',
  },
  {
    Header: 'Fulfilled',
    accessor: 'fulfilledAt',
    Cell: (
      cell: CellProps<ConsignmentRowData, ConsignmentRowData['fulfilledAt']>,
    ): React.ReactElement => (
      <div>{cell.value ? formatDateAndTime(cell.value) : '-'}</div>
    ),
    className: 'w-1/5',
  },
];

const processConsignmentData = (
  data: ConsignmentsQuery | undefined,
): ConsignmentRowData[] => {
  const consignmentRowData: ConsignmentRowData[] = [];

  if (!data?.consignments?.consignments) {
    return consignmentRowData;
  }

  for (const consignment of data.consignments.consignments) {
    if (!consignment.order?.id) continue;

    consignmentRowData.push({
      id: consignment.id,
      orderId: consignment.order.id,
      status: consignment.status,
      lineItems: consignment.lineItems?.length || 0,
      createdAt: new Date(consignment.createdAt),
      fulfilledAt: consignment.fulfilledAt
        ? new Date(consignment.fulfilledAt)
        : null,
    });
  }

  return consignmentRowData;
};

export default Consignments;
