import { Dropdown, Option } from 'components/dropdown-v7';
import { Input } from 'components/input';
import {
  ConsignmentFulfillmentVendor,
  ConsignmentsSearchInput,
  ConsignmentStatus,
} from 'graphql/types';
import { ReactElement, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  parseConsignmentFulfillmentVendors,
  parseConsignmentStatuses,
} from 'utils/misc';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';
import { validate } from 'uuid';

const statusOptions: Option<ConsignmentStatus>[] = [
  { label: 'Canceled', value: 'CANCELLED' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Delivery Failed', value: 'DELIVERY_FAILED' },
  { label: 'Failed Vendor Creation', value: 'FAILED_VENDOR_CREATION' },
  { label: 'Fulfilled', value: 'FULFILLED' },
  { label: 'Pending Vendor Creation', value: 'PENDING_VENDOR_CREATION' },
  { label: 'Vendor Created', value: 'VENDOR_CREATED' },
];

const fulfillmentVendorOptions: Option<ConsignmentFulfillmentVendor>[] = [
  { label: 'Apons', value: 'APONS' },
  { label: 'Consultation', value: 'CONSULTATION' },
  { label: 'Evermed', value: 'EVERMED' },
  { label: 'JMI', value: 'JMI' },
  { label: 'Pluripharm', value: 'PLURIPHARM' },
  { label: 'Shopify', value: 'SHOPIFY' },
  { label: 'Access', value: 'SVC_CORE_ACCESS' },
  { label: 'Titan', value: 'TITAN' },
];

export const ConsignmentFilter = ({
  onChange,
}: {
  onChange: () => void;
}): ReactElement => {
  const query = useGetNativeQuery();

  const { control, register, watch } = useForm<ConsignmentUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const id = watch().id;
  const statuses = watch().statuses;
  const fulfillmentVendors = watch().fulfillmentVendors;

  const changeUrl = useChangeUrl();
  useDebouncedEffect(
    () => {
      changeUrl({
        params: {
          id: validate(id ?? '') ? id : null,
          statuses,
          fulfillmentVendors,
        },
      });
      onChange();
    },
    0,
    [id, statuses, fulfillmentVendors, onChange],
  );

  return (
    <form>
      <div className="flex space-x-2 mb-8">
        <div className="w-1/3">
          <Input
            label="Search by ID"
            placeholder="Status and Vendor filters will not apply"
            {...register('id')}
          />
        </div>
        <div className="w-1/3">
          <Dropdown
            name="statuses"
            label="Statuses"
            options={statusOptions}
            control={control}
            isMulti
          />
        </div>
        <div className="w-1/3">
          <Dropdown
            name="fulfillmentVendors"
            label="Fulfillment Vendors"
            options={fulfillmentVendorOptions}
            control={control}
            isMulti
          />
        </div>
      </div>
    </form>
  );
};

type ConsignmentUrlQueryParams = {
  id: string | null;
  statuses: string[] | null;
  fulfillmentVendors: string[] | null;
};

const useGetNativeQuery = (): ConsignmentUrlQueryParams => {
  const urlQuery = useUrlQuery();

  const statuses = urlQuery.getAll('statuses');
  const fulfillmentVendors = urlQuery.getAll('fulfillmentVendors');

  return {
    id: urlQuery.get('id')?.trim() || null,
    statuses: statuses.length ? statuses : null,
    fulfillmentVendors: fulfillmentVendors.length ? fulfillmentVendors : null,
  };
};

export const useBuildConsignmentQueryFilter = (): ConsignmentsSearchInput => {
  const { id, statuses, fulfillmentVendors } = useGetNativeQuery();

  return useMemo(() => {
    let ids: string[] | undefined;
    if (id && validate(id ?? '')) {
      ids = [id];
    }

    const parsedStatuses = parseConsignmentStatuses(statuses);
    const parsedFulfillmentVendors =
      parseConsignmentFulfillmentVendors(fulfillmentVendors);

    const filter = {
      statuses: parsedStatuses,
      fulfillmentVendors: parsedFulfillmentVendors,
    };

    return {
      ids,
      filter,
    };
  }, [id, statuses, fulfillmentVendors]);
};
